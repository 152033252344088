import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const PrivacyPolicy = () => {

    return (
        <>
            <SEO title="Privacy Policy" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Privacy Policy"
                page="Privacy Policy"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <div className="section-title">
                                    <h5 className="title">This Privacy policy was published on July 20th, 2024.</h5>
                                </div>
                                <h4>GDPR compliance</h4>
                                <p>RSDHUB complies with GDPR to ensure your data is protected and your privacy rights are respected. We obtain your consent before collecting any personal information and provide options to withdraw consent at any time.</p>
                                <h4>About RSDHUB</h4>
                                <p>At RSDHUB, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information. For any inquiries, please contact us at <a href="mailto:info@rsdhub.com">info@rsdhub.com</a> </p>
                                <h4>When we collect personal data about you</h4>
                                <p className="mb--20">We collect personal data when you interact with our services, such as registering, purchasing, or contacting us. This includes your name, email, and any other relevant information.</p>
                                {/* <ul>
                                    <li>Email is a crucial channel in any marketing.</li>
                                    <li>Curious what to say? How to say it?</li>
                                    <li>Whether you’re kicking off a new campaign.</li>
                                    <li>Habitasse per feugiat aliquam luctus accumsan curae</li>
                                </ul> */}
                                <h4>Why we collect and use personal data</h4>
                                <p className="mb--20">We collect personal data to provide and improve our services, communicate with you, and ensure a personalized experience. We also use your data to comply with legal obligations and for security purposes.</p>
                                {/* <ul>
                                    <li>Nulla facilisi. Sed pulvinar nec purus eu sollicitudin. Quisque ut tempus quam, in cursus eros.</li>
                                    <li>Fusce malesuada luctus velit eu tempor. Pellentesque habitant morbi tristique senectus et netus et.</li>
                                    <li>Pellentesque ornare nulla est, non blandit sapien lacinia nec. Nulla ac velit id est mattis faucibus.</li>
                                    <li>Aliquam lacus nisi, lobortis non diam eget, malesuada bibendum justo. Praesent fringilla sagittis ex, ac molestie ipsum ullamcorper a.</li>
                                    <li>Vestibulum nulla tortor, aliquam at porta in, hendrerit sed nibh.</li>
                                </ul> */}
                                <h4>Type of personal data collected</h4>
                                <p>We collect various types of data, including personal information (name, email) and non-personal information (browser type, operating system). This data helps us understand user behavior and enhance our services.</p>
                                <h4>Information we collect automatically</h4>
                                <p>We automatically collect information such as IP addresses, browser type, and operating system. This data is used to improve our services and ensure a better user experience.</p>
                                <h4>The use of cookies and web beacons</h4>
                                <p className="mb--20">We use cookies and web beacons to track activity on our site. These tools help us analyze user behavior, personalize your experience, and improve our services. They do not personally identify you.</p>
                                {/* <p className="mb--20">may log information using digital images called web beacons on our Site or in our emails.</p> */}
                                {/* <p>This information is used to make our Site work more efficiently, as well as to provide business and marketing information to the owners of the Site, and to gather such personal data as browser type and operating system, referring page, path through site, domain of ISP, etc. for the purposes of understanding how visitors use our Site. Cookies and similar technologies help us tailor our Site to your personal needs, as well as to detect and prevent security threats and abuse. If used alone, cookies and web beacons do not personally identify you.</p> */}
                                <h4>How long we keep your data</h4>
                                <p className="mb--20">We store your data for as long as necessary to fulfill the purposes for which it was collected. This includes compliance with legal obligations, resolving disputes, and ensuring security.</p>
                                <h4>Your rights to your personal data</h4>
                                <p>You have the right to access, correct, or delete your personal data. You can also object to or restrict the processing of your data. To exercise these rights, please contact us at info@rsdhub.com.</p>
                                <h4>Hotjar’s privacy policy</h4>
                                <p>We use Hotjar for analytics and to understand user behavior on our site. Hotjar’s privacy policy can be reviewed on their website. We ensure all data shared with third parties is anonymized.</p>
                                <h4>Changes to this Privacy Policy</h4>
                                <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the updated policy on our website. Please review this policy periodically for any updates.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default PrivacyPolicy;
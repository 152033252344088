import React from 'react';
import SectionTitle from '../../elements/section-title/SectionTitle';

const Datas = [
    {
        id: 1,
        title: "Proven Track Record of Success",
        para: "Our extensive portfolio showcases our ability to deliver top-notch digital solutions that consistently meet and exceed client expectations, ensuring growth and success."
    },
    {
        id: 2,
        title: "Comprehensive Digital Services",
        para: "From innovative design to robust development and effective marketing, we offer a full range of services, covering all your digital needs under one roof."
    },
    {
        id: 3,
        title: "Experienced and Skilled Team",
        para: "Our team consists of industry experts with years of experience, bringing deep knowledge and innovative solutions to help your business thrive in the digital age."
    },
    {
        id: 4,
        title: "Client-Centered Approach",
        para: "We prioritize your goals and needs, working closely with you to create customized solutions that effectively drive results and meet your business objectives."
    },
    {
        id: 5,
        title: "Cutting-Edge Technology and Tools",
        para: "We utilize the latest technologies and tools to deliver efficient, scalable, and secure digital solutions, keeping your business ahead of the curve."
    },
    {
        id: 6,
        title: "Commitment to Excellence",
        para: "We are dedicated to delivering excellence in every project, ensuring your satisfaction through high-quality work and a focus on long-term success."
    }
]




const AboutThree = () => {
    return (
        <div className="section section-padding bg-color-dark pb--80 pb_lg--40 pb_md--20">
            <div className="container">
                <SectionTitle 
                    subtitle="Our Valus"
                    title="Why should you work with us?"
                    description="We prioritize your goals, working closely with you to create customized solutions that effectively drive results and meet objectives."
                    textAlignment="heading-left heading-light-left mb--100"
                    textColor=""
                />

                <div className="row">
                    {Datas.map((data) => (
                        <div className="col-lg-4" key={data.id}>
                            <div className="about-quality">
                                <h3 className="sl-number">{data.id}</h3>
                                <h5 className="title">{data.title}</h5>
                                <p>{data.para}</p>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
            <ul className="list-unstyled shape-group-10">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/circle-1.png"} alt="Circle" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-3.png"} alt="Circle" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/bubble-5.png"} alt="Circle" /></li>
            </ul>
        </div>
    )
}

export default AboutThree;
import React from 'react';
import { Link } from 'react-router-dom';


const AboutFive = () => {
    return (
      <div className="section-padding-equal">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-lg-7">
                    <div className="about-team">
                    <div className="thumbnail">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-2.png"} alt="thumbnail" />
                    </div>
                    </div>
                </div>
                <div className="col-lg-5">
                    <div className="about-team">
                    <div className="section-heading heading-left">
                        <span className="subtitle">Our Team</span>
                        <h2>Alone we can do so little; together we can do so much.</h2>  
                        <p>Collaboration is at the heart of our approach at RSDHUB. We believe that the power of teamwork can transform any challenge into an opportunity. By working together with our clients, we pool our expertise, creativity, and resources to create innovative solutions that drive success. Our collaborative process ensures that every project benefits from diverse perspectives and a shared commitment to excellence. Whether it’s a complex development task or a comprehensive marketing strategy, our team works hand in hand with you to achieve outstanding results.</p>
                        <p>At RSDHUB, we understand that true innovation and growth come from a collective effort. Our team of experts brings a wealth of knowledge and experience across various digital domains, and by partnering with our clients, we ensure that every project is tailored to meet their unique needs. This synergy allows us to tackle even the most daunting challenges with confidence and creativity. Together, we can achieve remarkable things, turning your vision into reality and setting new standards of success in the digital landscape.</p>
                        <Link to="/team" className="axil-btn btn-large btn-fill-primary">Our Team</Link>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    )
}

export default AboutFive;
import React from 'react';
import FooterOne from '../common/footer/FooterOne';
import HeaderOne from '../common/header/HeaderOne';
import CtaLayoutOne from '../component/cta/CtaLayoutOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import SEO from '../common/SEO';


const TermsOfUse = () => {

    return (
        <>
            <SEO title="Terms Of Use" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Terms Of Use"
                page="Terms Of Use"
                />
                <div className="section-padding privacy-policy-area">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="privacy-policy-content">
                                <h4>Acceptance of Terms and Conditions</h4>
                                <p>By accessing or using the services provided by RSDHUB, including any associated content, features, and functionalities, you agree to be bound by these Terms of Use. This agreement forms a binding contract between you and RSDHUB. If you do not agree to these terms, you must refrain from using our services. These terms apply to all users, including visitors, registered users, and others who access or use the services. It is your responsibility to review these terms regularly to stay informed of any updates.
                                </p>
                                <h4>User Accounts and Security Obligations</h4>
                                <p>To access certain features of our services, you may be required to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to maintain its accuracy. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must immediately notify RSDHUB of any unauthorized use of your account or any other breach of security. RSDHUB will not be liable for any loss or damage arising from your failure to comply with these obligations.</p>
                                <h4>User Conduct and Responsibilities</h4>
                                <p>As a condition of use, you agree not to use our services for any purpose that is unlawful or prohibited by these terms. This includes, but is not limited to, engaging in any activity that may harm, harass, or exploit other users, infringe on the intellectual property rights of others, or violate any applicable laws and regulations. You also agree not to interfere with the operation of our services, such as by introducing viruses, spamming, or hacking. RSDHUB reserves the right to investigate and take appropriate legal action against anyone who violates these provisions.</p>
                                <h4>Intellectual Property and Content Ownership</h4>
                                <p>All content and materials available on our platform, including but not limited to text, graphics, logos, images, software, and audio clips, are the property of RSDHUB or its licensors and are protected by intellectual property laws. You may not use, reproduce, modify, or distribute any content from our services without the express written permission of RSDHUB. Any unauthorized use of our content may violate copyright, trademark, and other laws and is strictly prohibited. Users retain ownership of any content they upload, but grant RSDHUB a worldwide, non-exclusive, royalty-free license to use, display, and distribute such content as part of our services.</p>
                                <h4>Limitation of Liability and Disclaimers</h4>
                                <p>RSDHUB provides its services "as is" and "as available" without any warranties, express or implied, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, and non-infringement. RSDHUB does not guarantee that the services will be uninterrupted, secure, or free of errors. To the fullest extent permitted by law, RSDHUB shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our services. This includes any errors or omissions in the content, loss of data, or any other loss or damage of any kind incurred as a result of using our services.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CtaLayoutOne />
            <FooterOne parentClass="" />
            </main>
        </>
    )
}

export default TermsOfUse;
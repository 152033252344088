import React from 'react';
import FormOne from '../contact/FormOne';


const AboutOne = () => {
    return (
        <section className="section section-padding-equal bg-color-light">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-us">
                            <div className="section-heading heading-left mb-0">
                                <span className="subtitle">About Us</span>
                                <h2 className="title mb--40">We do design, develop &amp; marketing.</h2>
                                <p>Founded in 2021 in the USA, RSDHUB is a leading provider of comprehensive digital solutions. We specialize in designing and developing high-quality web, app, and software solutions tailored to meet the unique needs of our clients. Our team of experts also offers a range of services including digital marketing, SEO, e-commerce SEO, lead generation, video editing, and DevOps. We are committed to delivering innovative and effective solutions that drive growth and success for businesses.</p>
                                <p>Since our inception, RSDHUB has successfully completed numerous projects, helping clients achieve their goals and exceed their expectations. Our focus on excellence, creativity, and client satisfaction has established us as a trusted partner in the digital space. We look forward to continuing to deliver exceptional results and fostering long-term relationships with our clients.</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-5 col-lg-6 offset-xl-1">
                        <div className="contact-form-box">
                            <h3 className="title">Get a free Keystroke quote now</h3>
                           <FormOne />
                        </div>
                    </div>
                </div>
            </div>
            <ul className="shape-group-6 list-unstyled">
                <li className="shape shape-1"><img src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"} alt="Bubble" /></li>
                <li className="shape shape-2"><img src={process.env.PUBLIC_URL + "/images/others/line-4.png"} alt="line" /></li>
                <li className="shape shape-3"><img src={process.env.PUBLIC_URL + "/images/others/line-5.png"} alt="line" /></li>
            </ul>
        </section>
    )
}

export default AboutOne;
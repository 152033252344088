import React from 'react';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';
import TrackVisibility from 'react-on-screen';


const AboutFour = () => {
    return (
            <div className="section section-padding case-study-featured-area">
                <div className="container">
                    <div className="row">
                    <div className="col-xl-7 col-lg-6">
                        <div className="case-study-featured-thumb text-start">
                        <img src={process.env.PUBLIC_URL + "/images/others/case-study-4.png"} alt="travel" />
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="case-study-featured">
                        <div className="section-heading heading-left">
                            <span className="subtitle">Who we are</span>
                            <h2 className="title">Crafting Digital Success Together</h2>
                            <p>We are RSDHUB, a premier digital services provider based in the USA. Since our founding in 2021, we have successfully completed numerous projects across various industries. Our expertise spans design, development, and marketing, offering comprehensive solutions tailored to meet the unique needs of our clients. Whether it's crafting intuitive UI/UX designs, developing robust websites and mobile applications, or implementing effective digital marketing strategies, we are dedicated to delivering excellence in every project.</p>
                            <p>At RSDHUB, we believe in the power of innovation and collaboration. Our team of experienced professionals is committed to helping businesses thrive in the digital landscape. By leveraging the latest technologies and industry best practices, we create impactful and engaging digital experiences that drive results. Trust us to be your partner in digital transformation, providing the expertise and support you need to achieve your business goals.</p>
                            <Link to="/contact" className="axil-btn btn-fill-primary btn-large">Contact Us</Link>
                        </div>
                        <div className="case-study-counterup">
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="4" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Years on the market</span>
                            </div>
                            <div className="single-counterup">
                            <h2 className="count-number">
                            <TrackVisibility once>
                                {({isVisible}) => (
                                    <span className="number count">
                                        {isVisible ? <CountUp end="45" duration={1} /> : null}
                                    </span>
                                )}  
                            </TrackVisibility>
                            <span className="symbol">+</span>
                            </h2>
                            <span className="counter-title">Projects delivered so far</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default AboutFour;